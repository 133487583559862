.global-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 90vh;
}

.page-wrapper {
  display: flex;
  box-sizing: border-box;
  flex: 1 0 auto;
}

.page-footer {
  min-height: 60px;
  padding: 10px;
  line-height: 1.5;
  border-top: 1px solid #e6e6e6;
  box-sizing: border-box;
  background-color: black;
  color: #595959;
  font-size: 12px;
  text-align: center;
}