.campaign-resume {
  margin: 0 auto;
  padding: 20px;

  .p-card {
    margin-bottom: 20px;
  }

  .p-field {
    margin-bottom: 1rem;

    label {
      font-weight: bold;
      display: block;
      margin-bottom: 0.5rem;
    }

    p,
    a {
      margin: 0;
    }
  }

  .p-listbox-item {
    cursor: unset;
  }
}

.tag-container {
  display: flex;
}

.inline-list {
  list-style-type: none;
}