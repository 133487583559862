.p-fileupload-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.p-fileupload-item-content {
  padding-right: 1rem;
  display: flex;
  align-items: center;
}