.checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.checkbox-item {
    display: flex;
    align-items: center;
}

label {
    display: flex;
    align-items: center;
}

.checkbox-item .text-500 {
    margin-left: 2rem;
    padding-right: 2rem;
}

.label-text {
    margin-left: 0.5rem;
}