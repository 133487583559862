.p-timeline-event-opposite {
    display: contents;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
    margin-top: -0.5rem;
    margin-bottom: 1rem;
}

.browser-os-info {
    margin-top: 0.5rem;
    font-size: small;
}

.timeline-event-results .p-datatable table {
    border-collapse: collapse;
    width: 100%;
}

.timeline-event-results .p-datatable th,
.timeline-event-results .p-datatable td {
    border: 1px solid #ddd;
    padding: 8px;
}

.timeline-event-results .p-datatable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #f2f2f2;
    color: black;
}