.hide-checkbox .p-checkbox {
  cursor: default !important;
  pointer-events: none !important;
}

.hide-checkbox .p-checkbox-box {
  display: none !important;
  visibility: hidden !important;
}

.hide-checkbox .p-checkbox-input {
  display: none !important;
}

.hide-checkbox .p-checkbox-label {
  cursor: default !important;
}