.p-fieldset-content {
    padding: unset
}

.p-chip {
    display: inline-flex;
    align-items: center;
    padding: 0 0.75rem;
    height: 2rem;
    border-radius: 1rem;
    background-color: #007ad9;
    color: white;
    font-size: 0.875rem;
    line-height: 1.5;
    cursor: default;
    margin: 0.25rem;
}

.p-chip-text {
    display: flex;
    align-items: center;
}

.p-chip-icon {
    margin-right: 0.5rem;
}

.p-datepicker table td>span.p-highlight {
    background-color: rgb(183, 183, 243);
}

.confirmation-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.left-button {
    margin-right: auto;
}

.right-button {
    margin-left: auto;
}